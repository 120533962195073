import { graphql } from "gatsby"
import React from "react"
import { ContentfulIndividualCuratorPageQuery } from "../../../types/gatsby-graphql"
import { shortAddress } from "../../components/ConnectWalletMessage/ConnectWalletMessage"
import { NFTCard } from "../../components/NFTCard/NFTCard"
import ProfileSocialMedias from "../../components/ProfileSocialMedias/ProfileSocialMedias"

import { CapitalizeAll } from "../../utils/string"
import * as styles from "./Curator.module.scss"

const Curator = (props: any) => {
  const data: ContentfulIndividualCuratorPageQuery = props.data
  const artistName = [
    CapitalizeAll(data.contentfulCurator?.firstName!),
    CapitalizeAll(data.contentfulCurator?.middleName!),
    CapitalizeAll(data.contentfulCurator?.lastName!),
  ].join(" ")

  return (
    <div className={styles.curatorContainer}>
      <img
        className={styles.curatorBackground}
        src={data.contentfulCurator?.coverImage?.file?.url!}
        alt={data.contentfulCurator?.coverImage?.title!}
      />
      <div className={styles.curatorHeader}>
        <img
          src={data.contentfulCurator?.profilePicture?.file?.url!}
          alt={data.contentfulCurator?.profilePicture?.title!}
        />
        <div className={styles.curatorInner}>
          <div className={styles.curatorDetail}>
            <h1>{`${artistName}`}</h1>
            <h3>@{data.contentfulCurator?.username}</h3>
            <span>{data.contentfulCurator?.description?.description}</span>
          </div>
          <div className={styles.curatorAditionalInfo}>
            <span>
              <b>Wallet Address:</b>
            </span>
            <span>{shortAddress(data.contentfulCurator?.walletAddress!)}</span>
            <div className={styles.curatorSocialMedia}>
              <ProfileSocialMedias
                facebook={data.contentfulCurator?.facebookUrl!}
                twitter={data.contentfulCurator?.twitterUrl!}
                instagram={data.contentfulCurator?.instagramUrl!}
                globe={data.contentfulCurator?.website!}
              />
            </div>
            <span>{data.contentfulCurator?.website}</span>
          </div>
        </div>
      </div>
      <div className={styles.curatorDetailTable}>
        <span>Represented by Howard Gallery (@howard)</span>
        <span>Senior Curator at Heiman Auctions (@heimanart)</span>
        <div className={styles.curatorDetailTableWallet}>
          <span>Wallet Address:</span>
          <span>{shortAddress(data.contentfulCurator?.walletAddress!)}</span>
        </div>
        <div className={styles.curatorDetailTableWebSite}>
          <span>Website:</span>
          <span>
            <b>{data.contentfulCurator?.website}</b>
          </span>
        </div>
        <div className={styles.curatorDetailTableSocialMedia}>
          <ProfileSocialMedias
            facebook={data.contentfulCurator?.facebookUrl!}
            twitter={data.contentfulCurator?.twitterUrl!}
            instagram={data.contentfulCurator?.instagramUrl!}
            globe={data.contentfulCurator?.website!}
          />
        </div>
      </div>

      {/* <div className={styles.curatorFilters}>
        <Tabs
          tabs={tabs}
          locationsProps={props}
          classname={styles.curatorTabs}
        />
      </div> */}

      <div className={styles.curatorNftsDetail}>
        <h2>{`Curated for: `}</h2>
        <h1>{`${data.contentfulCurator?.title}`}</h1>
      </div>
      <div className={styles.curatorNFTList}>
        {data.contentfulCurator?.assets?.map(edge => {
          return (
            <NFTCard
              title={edge?.title!}
              imgUrl={edge?.mainMedia?.file?.url!}
              price={0.45}
              by={`${edge?.artist?.firstName} ${edge?.artist?.lastName}`}
              ownedBy={edge?.artist?.username!}
              linkTo={`/artwork/${edge?.contentful_id}`}
            />
          )
        })}
      </div>
      <div className={styles.curatorArtworks}>
        <h1>{`${data.contentfulCurator?.collections?.name}`}</h1>
      </div>
      <div className={styles.curatorNFTList}>
        {data.contentfulCurator?.collections?.lots?.map(edge => {
          return (
            <NFTCard
              title={edge?.asset?.title!}
              imgUrl={edge?.asset?.mainMedia?.file?.url!}
              price={0.45}
              by={artistName}
              ownedBy={edge?.asset?.artist?.username!}
              linkTo={`/artwork/${edge?.contentful_id}`}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Curator

export const artistQuery = graphql`
  query ContentfulIndividualCuratorPage($contentful_id: String!) {
    contentfulCurator(contentful_id: { eq: $contentful_id }) {
      website
      walletAddress
      username
      twitterUrl
      title
      middleName
      lastName
      instagramUrl
      firstName
      facebookUrl
      contentful_id
      profilePicture {
        file {
          url
        }
        title
      }
      description {
        description
      }
      coverImage {
        file {
          url
        }
        title
      }
      collections {
        name
        slug
        contentful_id
        lots {
          contentful_id
          lotId
          ended
        }
      }
    }
  }
`
