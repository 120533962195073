// extracted by mini-css-extract-plugin
export var curatorAditionalInfo = "Curator-module--curatorAditionalInfo--bba0a";
export var curatorArtworks = "Curator-module--curatorArtworks--eb016";
export var curatorBackground = "Curator-module--curatorBackground--ef960";
export var curatorContainer = "Curator-module--curatorContainer--12254";
export var curatorDetail = "Curator-module--curatorDetail--8ab6c";
export var curatorDetailTable = "Curator-module--curatorDetailTable--35281";
export var curatorDetailTableWallet = "Curator-module--curatorDetailTableWallet--bbf12";
export var curatorDetailTableWebSite = "Curator-module--curatorDetailTableWebSite--d1dbd";
export var curatorFilters = "Curator-module--curatorFilters--8e8d8";
export var curatorHeader = "Curator-module--curatorHeader--c5c46";
export var curatorInner = "Curator-module--curatorInner--a57a9";
export var curatorNFTList = "Curator-module--curatorNFTList--9e6fb";
export var curatorNftsDetail = "Curator-module--curatorNftsDetail--4b564";
export var curatorSocialMedia = "Curator-module--curatorSocialMedia--75663";
export var curatorTabs = "Curator-module--curatorTabs--4c321";